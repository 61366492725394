import { RULE } from '@zeiss/ng-vis-vp-auth/constants';
import { AUTH_GUARD } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { vpRule } from '@zeiss/ng-vis-vp-auth/types';
const ROUTE_PATHS = {
  _: 'ddp',
  metadata: 'metadata',
  cutoffTime: 'cutoff-time',
  shift: 'shift',
  communicationStatus: 'communication-status',
  statusLocalLane: 'status-local-lane',
  statusLocalActivityType: 'status-local-activity-type',
  laneMap: 'lane-map',
  activityTypeMap: 'activity-type-map',
  communicationStatusMap: 'communication-status-map'
};
const ROUTE_CONFIG = {
  path: ROUTE_PATHS._,
  canActivate: [AUTH_GUARD],
  data: {
    title: 'external.ddp.routes.title',
    description: 'external.ddp.routes.desc',
    icon: 'zui-icon-time-time-date-time',
    showInMenu: true,
    cache: true,
    rule: vpRule({
      rule: RULE.access_ddp_v1,
      required: []
    }),
    routes: [{
      path: ''
    }, {
      path: ROUTE_PATHS.cutoffTime,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.ddp.routes.cutoffTime.title',
        description: 'external.ddp.routes.cutoffTime.desc',
        icon: 'zui-icon-time-time-time',
        hierarchy: 6,
        showInMenu: true,
        cache: true,
        rule: vpRule({
          rule: RULE.access_ddp_v1,
          required: ['ddp_cut_off']
        })
      }
    }, {
      path: ROUTE_PATHS.shift,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.ddp.routes.shift.title',
        description: 'external.ddp.routes.shift.desc',
        icon: 'zui-icon-file-file-file-transfer',
        hierarchy: 5,
        showInMenu: true,
        cache: true,
        rule: vpRule({
          rule: RULE.access_ddp_v1,
          required: ['ddp_shifts']
        })
      }
    }, {
      path: ROUTE_PATHS.communicationStatus,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.ddp.routes.communicationStatus.title',
        description: 'external.ddp.routes.communicationStatus.desc',
        icon: 'zui-icon-exchange',
        hierarchy: 4,
        showInMenu: true,
        cache: true,
        rule: vpRule({
          rule: RULE.access_ddp_v1,
          required: ['ddp_communication_status']
        })
      }
    }, {
      path: ROUTE_PATHS.statusLocalActivityType,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.ddp.routes.statusLocalActivityType.title',
        description: 'external.ddp.routes.statusLocalActivityType.desc',
        icon: 'zui-icon-laser-pattern-laser-pattern-matrix',
        hierarchy: 3,
        showInMenu: true,
        cache: true,
        rule: vpRule({
          rule: RULE.access_ddp_v1,
          required: ['ddp_activity_type_map']
        })
      }
    }, {
      path: ROUTE_PATHS.statusLocalLane,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.ddp.routes.statusLocalLane.title',
        description: 'external.ddp.routes.statusLocalLane.desc',
        icon: 'zui-icon-data-acquisition',
        hierarchy: 2,
        showInMenu: true,
        cache: true,
        rule: vpRule({
          rule: RULE.access_ddp_v1,
          required: ['ddp_lane_map']
        })
      }
    }, {
      path: ROUTE_PATHS.metadata,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.ddp.routes.metadata.title',
        description: 'external.ddp.routes.metadata.desc',
        icon: 'zui-icon-system-parameters',
        showInMenu: true,
        hierarchy: 1,
        rule: vpRule({
          rule: RULE.access_ddp_v1,
          required: ['ddp_metadata']
        })
      }
    }]
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { ROUTE_CONFIG, ROUTE_PATHS };
