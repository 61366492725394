import { SystemLanguage } from '@zeiss/ng-vis-common/types';
const deDEExternal = {
  ddp: {
    routes: {}
  }
};
const enGBExternal = {
  ddp: {
    routes: {
      title: 'DDP',
      desc: 'Tools for delivery date prediction',
      cutoffTime: {
        title: 'Cutoff Time',
        desc: 'Manage cutoff time'
      },
      shift: {
        title: 'Shifts',
        desc: 'Manage shifts'
      },
      communicationStatus: {
        title: 'Communication Status',
        desc: 'Manage communication status'
      },
      statusLocalActivityType: {
        title: 'Status Local Activity Type',
        desc: 'Manage status local activity type map'
      },
      statusLocalLane: {
        title: 'Status Local Lane',
        desc: 'Manage status local lane map'
      },
      metadata: {
        title: 'Metadata',
        desc: 'Manage metadata'
      }
    }
  }
};
const esESExternal = {
  ddp: {
    routes: {}
  }
};
const DDP_TRANSLATIONS = {
  [SystemLanguage['en-GB']]: {
    ...enGBExternal
  },
  [SystemLanguage['es-ES']]: {
    ...esESExternal
  },
  [SystemLanguage['de-DE']]: {
    ...deDEExternal
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { DDP_TRANSLATIONS };
